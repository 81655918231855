import React from "react";
import styled from "styled-components";
import { device } from "../constant/media";
import { MarkerText } from "../constant/text_style";

const FooterWrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #d9d9d9;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0px;
  
  ${device.tablet} {
    padding: 10px 0px;
  }
  
  ${device.mobile} {
    padding: 8px 0px;
  }
`;

function Footer() {
  return (
    <>
      <FooterWrapper>
        <MarkerText desktop="16px" tablet="14px" mobile="12px">
          MinyDinger
        </MarkerText>
      </FooterWrapper>
    </>
  );
}

export default Footer;
