import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { device } from "../../constant/media";

function NotFound() {
  return (
    <Layout>
      <NFBackground>
        404
        <br />
        Not Found :(
      </NFBackground>
    </Layout>
  );
}

const NFBackground = styled.div`
  display: flex;
  width: 100%;
  height: 80vh;

  justify-content: center;
  align-items: center;

  text-align: center;

  font-family: "NanumGyuRiEuiIrGi";
  font-size: 70px;

  ${device.tablet} {
    font-size: 56px;
  }

  ${device.mobile} {
    font-size: 36px;
  }
`;

export default NotFound;
