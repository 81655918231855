import styled from "styled-components";
import { device } from "../../constant/media";
import Layout from "../../components/Layout";
import IntroductionText from "./components/IntroductionText";
import MainVideo from "./components/MainVideo";
import Loading from "../../components/Loading";

function Home() {
  return (
    <Layout>
      <Loading />
      <HomeBackground>
        <HomeWrapper>
          <IntroductionText />
          <MainVideo />
        </HomeWrapper>
      </HomeBackground>
    </Layout>
  );
}

const HomeBackground = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  padding: 0px 16px;
  gap: 24px;

  max-width: 1200px;

  ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  }

  ${device.mobile} {
    padding-top: 10vh;
  }

  ${device.portrait} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ${device.landscape} {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

export default Home;
