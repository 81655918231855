import styled from "styled-components";
import { device } from "./media";

interface ITextProps {
  desktop?: string;
  tablet?: string;
  mobile?: string;
  fontWeight?: string;
}

const MarkerText = styled.span<ITextProps>`
  font-family: "Permanent Marker", cursive;
  font-size: ${(props) => props.desktop};

  ${device.tablet} {
    font-size: ${(props) => props.tablet};
  }

  ${device.mobile} {
    font-size: ${(props) => props.mobile};
  }
`;

const GyuRiText = styled.span<ITextProps>`
  font-family: "NanumGyuRiEuiIrGi";
  font-size: ${(props) => props.desktop};
  font-weight: ${(props) => props.fontWeight};

  ${device.tablet} {
    font-size: ${(props) => props.tablet};
  }

  ${device.mobile} {
    font-size: ${(props) => props.mobile};
  }
`;

const MaruText = styled.span<ITextProps>`
  font-family: "MaruBuri";
  font-size: ${(props) => props.desktop};
  font-weight: ${(props) => props.fontWeight};

  ${device.tablet} {
    font-size: ${(props) => props.tablet};
  }

  ${device.mobile} {
    font-size: ${(props) => props.mobile};
  }
`;

const MainText = styled.span<ITextProps>`
  font-family: "pretendard";
  font-size: ${(props) => props.desktop};
  font-weight: ${(props) => props.fontWeight};

  ${device.tablet} {
    font-size: ${(props) => props.tablet};
  }

  ${device.mobile} {
    font-size: ${(props) => props.mobile};
  }
`;

export { MarkerText, GyuRiText, MainText, MaruText };
