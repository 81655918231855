import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./constant/scroll_top";
import NotFound from "./pages/404/notFound";
import Home from "./pages/home/home";

function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
