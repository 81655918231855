import styled from "styled-components";
import { device } from "../../../constant/media";
import video from "../../../assets/videos/minydinger.mp4";

function MainVideo() {
  return <Video src={video} muted loop playsInline autoPlay />;
}

const Video = styled.video`
  display: flex;
  width: 55%;
  align-self: flex-end;
  justify-items: end;
  object-fit: contain;
  padding-bottom: 20px;

  ${device.tablet} {
    width: 55vh;
    position: fixed;
    align-self: center;
    justify-items: center;
    padding-left: 15px;
    bottom: 0;
  }

  ${device.mobile} {
    width: 48vh;
  }
`;

export default MainVideo;
