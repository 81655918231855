import styled from "styled-components";
import { device } from "../constant/media";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { MarkerText } from "../constant/text_style";
import { BiBookContent } from "react-icons/bi";

function Header() {
  return (
    <>
      <Navi>
        <NaviWrapper>
          <LogoPart href="/">
            <LogoImg src={logo} alt="logo" />
            <MarkerText desktop="24px" tablet="20px" mobile="18px">
              MinyDinger
            </MarkerText>
          </LogoPart>
          <Menu>
            <MenuItem href="https://en-light.notion.site/f8d51f1827f64a508448bc7db66755e7">
              portfolio.&nbsp;
            </MenuItem>
          </Menu>
        </NaviWrapper>
      </Navi>
    </>
  );
}

const Navi = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

const NaviWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 0px 28px;

  ${device.tablet} {
    padding: 5px 20px;
  }
  ${device.mobile} {
    padding: 5px 16px;
  }
`;

const LogoPart = styled.a`
  display: flex;
  align-items: center;

  ${device.tablet} {
  }
  ${device.mobile} {
  }
`;

const LogoImg = styled.img`
  width: 70px;
  object-fit: contain;

  ${device.tablet} {
    width: 60px;
  }
  ${device.mobile} {
    width: 50px;
  }
`;

const Menu = styled.div`
  ${device.tablet} {
  }
  ${device.mobile} {
  }
`;

const MenuItem = styled.a`
  font-family: "Acme", sans-serif;
  font-size: 18px;
  background-color: white;
  border: 2px solid #000000;
  border-radius: 12px;
  padding: 8px 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${device.tablet} {
    font-size: 16px;
    padding: 4px 14px;
    border-radius: 10px;
  }

  ${device.mobile} {
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 8px;
    span {
      display: none;
    }
  }
`;

export default Header;
