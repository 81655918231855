import styled from "styled-components";
import { device } from "../../../constant/media";
import { GyuRiText } from "../../../constant/text_style";
import Typewriter from "typewriter-effect";
import RelatedInfo from "./RelatedInfo";

function IntroductionText() {
  return (
    <TextSection>
      <GyuRiText fontWeight="light" desktop="80px" mobile="7vh" tablet="7.5vh">
        안녕하세요
      </GyuRiText>
      <HookText>
        <GyuRiText fontWeight="bold" desktop="80px" tablet="7.5vh" mobile="7vh">
          "
        </GyuRiText>
        <GyuRiText fontWeight="bold" desktop="80px" tablet="7.5vh" mobile="7vh">
          <Typewriter
            options={{
              loop: true,
              delay: 100,
              autoStart: true,
              cursor: "",
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString("기록하는")
                .pauseFor(2000)
                .deleteAll(200)
                .typeString("꿈꾸는")
                .pauseFor(2000)
                .deleteAll(200)
                .typeString("개발하는")
                .pauseFor(2000)
                .deleteAll(200)
                .typeString("함께하는")
                .pauseFor(2000)
                .deleteAll(200)
                .typeString("크리에이터")
                .pauseFor(3000)
                .deleteAll(200)
                .start();
            }}
          />
        </GyuRiText>
        <GyuRiText fontWeight="bold" desktop="80px" tablet="7.5vh" mobile="7vh">
          "
        </GyuRiText>
      </HookText>
      <GyuRiText fontWeight="light" desktop="80px" tablet="7.5vh" mobile="7vh">
        MINY 입니다:)
      </GyuRiText>
      <RelatedInfo />
    </TextSection>
  );
}

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;

  ${device.tablet} {
    position: fixed;
    top: 100px;
    width: 50%;
    min-width: 320px;
  }

  ${device.mobile} {
    position: fixed;
    top: calc(90px - 1.5vh);
  }
`;

const HookText = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;

  ${device.mobile} {
    width: 30vh;
  }
`;

export default IntroductionText;
