export const size = {
  mobile: 540,
  tablet: 999,
  desktop: 1000,
};

export const ratio = {
  portrait: 1 / 1,
  landscape: 1 / 1,
};

export const device = {
  mobile: `@media screen and (max-width: ${size.mobile}px)`,
  tablet: `@media screen and (max-width: ${size.tablet}px)`,
  desktop: `@media screen and (min-width: ${size.desktop}px)`,
  portrait: `@media (min-aspect-ratio: 1/1.5)`,
  landscape: `@media (min-aspect-ratio: 1.5/1)`,
};
