import "react-circular-progressbar/dist/styles.css";
import { useState, useEffect, useRef } from "react";
import video from "../assets/videos/miny_loading.mp4";

import styled from "styled-components";
import { device } from "../constant/media";

const Loading: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
      setTimeout(() => {
        videoRef.current!.pause();
      }, 3000);
    }
  }, []);

  const getText = async () => {};

  useEffect(() => {
    setLoading(true);
    getText();
    setLoading(false);
  }, []);

  return loading ? (
    <Background>
      <Video src={video} muted loop playsInline autoPlay />
    </Background>
  ) : (
    <></>
  );
};

const Video = styled.video`
  display: flex;
  width: 15%;
  object-fit: contain;

  border-radius: 15px;

  margin-bottom: 40px;

  ${device.mobile} {
    width: 35%;
  }
`;

const Background = styled.div`
  background-color: rgb(255, 255, 255, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;

  justify-content: center;
  align-items: center;

  flex-direction: column;
`;

export default Loading;
