import React from "react";
import { Helmet } from "react-helmet";
// import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function Layout(props: { title?: string; children: React.ReactNode }) {
  // const location = useLocation();
  return (
    <>
      <Helmet>
        <title>{props.title ?? "MinyDinger"}</title>
        {/* <meta property="og:title" content={props.title ?? "프로젝트 명"} />
        <meta property="og:description" content="프로젝트 설명" />
        <meta property="og:image" content="%PUBLIC_URL%/assets/thumnail.png" />
        <meta
          property="og:url"
          content={`www.enen.co.kr${location.pathname}`}
        /> */}
      </Helmet>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </>
  );
}

export default Layout;
