import React from "react";
import { SiGmail, SiInstagram, SiGithub } from "react-icons/si";
import styled from "styled-components";
import { device } from "../../../constant/media";

function RelatedInfo() {
  return (
    <Icons>
      <a href="mailto:minydinger@gmail.com">
        <SiGmail id="icon" />
      </a>
      <a href="https://www.instagram.com/minydinger/">
        <SiInstagram id="icon" />
      </a>
      <a href="https://github.com/Minyoung-Jang/">
        <SiGithub id="icon" />
      </a>
    </Icons>
  );
}

const Icons = styled.div`
  display: flex;
  width: 40%;
  max-width: 200px;
  min-width: 100px;
  justify-content: space-around;
  align-items: center;
  padding-top: 30px;

  ${device.tablet} {
    padding-top: 3vh;
  }
  ${device.mobile} {
    padding-top: 2vh;
  }

  #icon {
    font-size: 24px;
    ${device.tablet} {
      font-size: 20px;
    }
    ${device.mobile} {
      font-size: 18px;
    }
  }
`;

export default RelatedInfo;
